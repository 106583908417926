<template>
  <VouchCategory
      title="Für die ganze Familie"
      :isMinimal="true"
      :ids="vouchers"
      :showButton="false"
      :hideTitle="true"
      :isPlugin="true"
  />
</template>
<script>
  import VouchCategory from '_components/VouchCategory';

  export default {
    name: 'Plugin',
    components: {
      VouchCategory
    },
    data() {
      return {
        vouchers: this.$route.query.vouchers,
      }
    },
  }
</script>
<style lang='css' scoped>
</style>